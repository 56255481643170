/**=====================
    Confetti CSS Start
==========================**/

.confetti-wrapper {
  position: fixed !important;
  top: 0;
  width: 100vw;
  height: 100vh;
  left: 0;
  z-index: 999;
  display: none;

  &.show {
    display: block;
  }
}

[class|="confetti"] {
  position: absolute;
}

$colors: (#d13447, #ffbf00, #263672);

@for $i from 0 through 150 {
  $w: random(18);
  $l: random(100);

  .confetti-#{$i} {
    width: #{$w}px;
    height: #{$w * 0.4}px;
    background-color: nth($colors, random(3));
    top: -10%;
    left: unquote($l + "%");
    opacity: random() + 0.5;
    transform: rotate(#{random() * 360}deg);
    animation: drop-#{$i} unquote(8 + random() + "s") unquote(random() + "s") infinite;
  }

  @keyframes drop-#{$i} {
    100% {
      top: 110%;
      left: unquote($l + random(8) + "%");
    }
  }
}
