/**=====================
    User dashboard start
==========================**/
.chart-padding {
  background-color: $white;
  padding: 20px;
  border-radius: 6px;
}

.chart-title {
  margin-bottom: 20px;

  h3 {
    font-size: 24px;
    font-weight: 600;
  }
}

.dashboard-title {
  margin-bottom: 22px;

  h3 {
    font-size: 20px;
    line-height: 1.3;
    position: relative;
    font-weight: 600;
  }

  &.dashboard-flex {
    display: flex;

    button {
      margin-left: auto;
    }
  }
}

.user-dashboard-section {
  .title {
    h2 {
      font-size: 26px;
    }
  }

  .dashboard-left-sidebar {
    background: $light-gray;
    box-shadow: 0 0 8px rgba($title-color, 0.14);
    border-radius: 10px;
    overflow: hidden;
    position: sticky;
    top: 107px;
    z-index: 0;

    @include mq-max(lg) {
      @include pseudowh($width: 300px, $height: 100vh);
      position: fixed;
      top: 0;
      left: -320px;
      border-radius: 0;
      z-index: 6;
      overflow-y: auto;
      overflow-x: hidden;
      transition: all 0.3s ease-in-out;

      body.rtl & {
        left: unset;
        right: -320px;
      }

      &.show {
        left: 0;

        body.rtl & {
          left: unset;
          right: 0;
        }
      }
    }

    .close-button {
      @include pseudowh($width: 30px, $height: 30px);
      @include flex_common;
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1;
      background: $light-gray;
      border-radius: 100%;

      .close-sidebar {
        border: none;
        font-size: 15px;
        background-color: transparent;
        color: $content-color;
        padding: 2px 0 0;
      }
    }

    .profile-box {
      position: relative;

      .cover-image {
        position: relative;
        overflow: hidden;

        img {
          @include pseudowh($width: 100%, $height: 150px);
          object-fit: cover;

          @include mq-max(lg) {
            height: 120px;
          }
        }

        &:before {
          @include pos;
          @include pseudowh;
          top: 0;
          left: 0;
          background-color: $title-color;
          opacity: 0.15;
        }

        .cover-icon {
          @include pseudowh($width: 30px, $height: 30px);
          @include flex_common;
          position: absolute;
          top: 8px;
          right: 8px;
          z-index: 1;
          background-color: $white;
          border-radius: 100%;
          color: $content-color;
        }
      }

      .profile-contain {
        padding: 0 15px;

        .profile-image {
          margin: 0 auto;
          text-align: center;
          margin-top: -50px;
          z-index: 1;
          position: relative;

          .user-round {
            h4 {
              @include pseudowh($width: calc(93px + (108 - 93) * ((100vw - 320px) / (1920 - 320))),
                $height: calc(93px + (108 - 93) * ((100vw - 320px) / (1920 - 320))));
              object-fit: contain;
              background-color: $light-gray;
              border-radius: 100%;
              @include flex_common;
              font-size: 40px;
              border-radius: 100%;
              box-shadow: 2px 3px 8px rgba($title-color, 0.19);
              margin: 0 auto;
            }
          }

          img {
            @include pseudowh($width: calc(93px + (108 - 93) * ((100vw - 320px) / (1920 - 320))),
              $height: calc(93px + (108 - 93) * ((100vw - 320px) / (1920 - 320))));
            object-fit: contain;
            background-color: $light-gray;
            border-radius: 100%;
            padding: 5px;
            border: 1px solid $border-color;
            box-shadow: 2px 3px 8px rgba($title-color, 0.32);
          }

          .cover-icon {
            @include pseudowh($width: 30px, $height: 30px);
            @include flex_common;
            position: absolute;
            bottom: 0;
            left: 55%;
            z-index: 1;
            background-color: $white;
            border-radius: 100%;
            color: $content-color;

            i {
              position: relative;

              input {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                opacity: 0;
                width: 35px;
              }
            }
          }
        }

        .profile-name {
          margin-top: calc(10px + (13 - 10) * ((100vw - 320px) / (1920 - 320)));
          text-align: center;
          padding-bottom: calc(12px + (18 - 12) * ((100vw - 320px) / (1920 - 320)));
          border-bottom: 1px solid #ddd;

          h3 {
            font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
            font-weight: 600;
            letter-spacing: 0.7px;
          }

          h6 {
            font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
            margin-top: 4px;

            span {
              margin: 0 8px;
            }
          }
        }
      }
    }

    .user-nav-pills {
      flex-wrap: wrap;
      gap: 0;
      margin: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320))) 0 6px;
      margin-top: 0;

      .logout-cls {
        margin-top: 0;
        padding-top: 20px;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 20px;
        border-top: 1px solid #ddd;

        .btn {
          background-color: #1aa488;
          color: white;
          padding: 10px;
        }
      }

      .nav-item {
        width: 100%;
        display: block;

        .nav-link {
          font-size: calc(17px + (18 - 17) * ((100vw - 320px) / (1920 - 320)));
          position: relative;
          color: $content-color;
          width: 100%;
          text-align: left;
          padding: calc(10px + (13 - 10) * ((100vw - 320px) / (1920 - 320))) calc(10px + (13 - 10) * ((100vw - 320px) / (1920 - 320))) calc(10px + (13 - 10) * ((100vw - 320px) / (1920 - 320))) calc(19px + (23 - 19) * ((100vw - 320px) / (1920 - 320)));
          font-weight: 500;
          z-index: 0;
          overflow: hidden;
          border-radius: 0;
          display: flex;
          align-items: center;

          body.rtl & {
            padding: calc(10px + (13 - 10) * ((100vw - 320px) / (1920 - 320))) calc(19px + (23 - 19) * ((100vw - 320px) / (1920 - 320))) calc(10px + (13 - 10) * ((100vw - 320px) / (1920 - 320))) calc(10px + (13 - 10) * ((100vw - 320px) / (1920 - 320)));
          }

          &.active,
          .show>.nav-link {
            border-left: 3px solid var(--theme-color);
            font-weight: 600;
            background-color: transparent;
            color: var(--theme-color);

            body.rtl & {
              border-right: 3px solid var(--theme-color);
              border-left: unset;
            }

            &::before {
              @include pseudowh;
              @include pos;
              top: 0;
              left: 0;
              background: var(--theme-color);
              z-index: -1;
              opacity: 0.1;

              body.rtl & {
                left: unset;
                right: 0;
              }
            }
          }

          .feather {
            @include pseudowh($width: calc(18px + (19 - 18) * ((100vw - 320px) / (1920 - 320))),
              $height: auto);
            margin-right: 10px;

            body.rtl & {
              margin-right: unset;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  .dashboard-right-sidebar {
    background-color: $light-gray;
    padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 8px;
    height: 100%;

    .right-option {
      display: flex;
      align-items: center;
      gap: 15px;
    }

    .no-data-added {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      img {
        width: 100%;
      }
    }

    @include mq-max(lg) {
      height: auto;
    }

    .info-box {
      margin-bottom: 30px;
      display: flex;
      padding: 16px;
      border-radius: 0;
      background-color: white;
      border-left: 3px solid var(--theme-color);

      .counter {
        font-size: 18px;
        font-weight: 500;
        gap: 9px;
      }
    }

    .point-ratio {
      background: #f8f8f8;
      padding: 18px;
      border: 1px solid #dcdcdc00;
      margin: 0 0 0 auto;

      @include mq-max(md) {
        margin: 0;
        display: inline-block;
        margin-top: 6px;
      }
    }

    .dashboard-bg-box {
      padding: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
      background-color: $white;
      border-radius: 6px;

      .profile-image {
        img {
          @media (max-width: 1400px) {
            display: none;
          }
        }
      }

      td {
        border-bottom: none;
        font-size: 16px;
      }

      +.dashboard-bg-box {
        margin-top: 24px;
      }
    }

    .total-box {
      margin: 30px 0;

      .totle-contain {
        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 15px);
        background-color: $white;
        padding: calc(16px + (25 - 16) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 9px;
        align-items: center;
        position: relative;
        overflow: hidden;
        z-index: 0;

        @include mq-max(md) {
          display: block;
        }

        &:hover {
          .img-1 {
            width: calc(84px + (96 - 84) * ((100vw - 320px) / (1920 - 320)));
            transform: translateY(-50%) rotate(-7deg);
            opacity: 0.2;
            right: -19px;

            body.rtl & {
              right: unset;
              left: -19px;
              transform: translateY(-50%) rotate(7deg);
            }
          }
        }

        .wallet-point-box {
          display: flex;
          align-items: center;
          gap: 10px;

          img {
            width: calc(54px + (60 - 54) * ((100vw - 320px) / (1920 - 320)));
          }
        }

        .img-1 {
          @include center(vertical);
          width: calc(78px + (90 - 78) * ((100vw - 320px) / (1920 - 320)));
          position: absolute;
          opacity: 0.12;
          right: -30px;
          transition: all 0.3s ease-in-out;
          filter: grayscale(1);

          body.rtl & {
            right: unset;
            left: -30px;
          }
        }

        .totle-detail {
          h5 {
            margin-bottom: calc(3px + (7 - 3) * ((100vw - 320px) / (1920 - 320)));
            color: $content-color;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
          }

          h3 {
            font-weight: 600;
          }
        }
      }
    }

    .dashboard-home {

      .title-header {
        .d-flex {
          flex-wrap: wrap;
          gap: 5px;
        }
      }
      .dashboard-user-name {
        h6 {
          font-size: 16px;
          margin-bottom: 10px;
        }

        p {
          margin: 0;
          font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
          line-height: 1.5;
          letter-spacing: 0.3px;
        }
      }

      .dashboard-contant-title {
        border-bottom: 1px solid #ddd;
        padding-bottom: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));

        h4 {
          @include flex_common($dis: flex, $align: center, $justify: space-between);

          a {
            font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));

            &:hover {
              color: var(--theme-color);
            }
          }
        }
      }

      .dashboard-detail {
        h6 {
          margin-bottom: calc(4px + (8 - 4) * ((100vw - 320px) / (1920 - 320)));
          line-height: 1.6;
        }

        a {
          &:hover {
            color: var(--theme-color);
          }
        }
      }
    }

    .dashboard-order {
      .order-contain {
        @include flex_wrap($dis: flex,
          $wrap: wrap,
          $gap: calc(14px + (30 - 14) * ((100vw - 320px) / (1920 - 320))));

        .order-box {
          .order-container {
            @include flex_wrap($dis: flex,
              $wrap: wrap,
              $gap: calc(8px + (14 - 8) * ((100vw - 320px) / (1920 - 320))));
            align-items: center;

            .order-icon {
              position: relative;
              z-index: 0;
              color: var(--theme-color);
              padding: 9px;
              border-radius: 100%;
              overflow: hidden;

              &::before {
                @include pos;
                @include pseudowh;
                left: 0;
                top: 0;
                background-color: var(--theme-color);
                opacity: 0.1;
                z-index: -1;
              }
            }

            .order-detail {
              h4 {
                font-weight: 600;
                display: flex;
                align-items: center;
                letter-spacing: 0.6px;
                font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));

                span {
                  font-size: 12px;
                  font-weight: 400;
                  color: $white;
                  background: linear-gradient(-90deg, #ff6b6b 0%, #ff4f4f 100%);
                  padding: 4px 6px;
                  border-radius: 4px;
                  margin-left: calc(9px + (20 - 9) * ((100vw - 320px) / (1920 - 320)));
                  font-weight: 600;

                  body.rtl & {
                    margin-left: unset;
                    margin-right: calc(9px + (20 - 9) * ((100vw - 320px) / (1920 - 320)));
                  }

                  &.success-bg {
                    background: var(--theme-color);
                  }
                }
              }

              h6 {
                margin-top: 6px;
                font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
                letter-spacing: 0.6px;
                font-weight: 300;
                line-height: 1.5;
              }
            }
          }

          .product-order-detail {
            @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 20px);
            background-color: $light-gray;
            padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
            margin-top: 30px;
            border-radius: 8px;
            align-items: center;

            @include mq-max(xl) {
              display: block;
            }

            @include mq-max(lg) {
              display: flex;
            }

            @include mq-max(md) {
              display: block;
            }

            &:hover {
              .order-image {
                img {
                  transform: scale(1.1);
                }
              }
            }

            .order-image {
              display: block;

              @include mq-max(xl) {
                text-align: center;
                margin-bottom: calc(14px + (30 - 14) * ((100vw - 320px) / (1920 - 320)));
              }

              img {
                transition: all 0.3s ease-in-out;
              }
            }

            .order-wrap {
              a {
                display: block;
                color: $title-color;

                &:hover {
                  color: $title-color;
                }

                h3 {
                  font-weight: 600;
                  margin-bottom: 6px;
                }
              }

              p {
                line-height: 1.5;
                font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
              }

              .product-size {
                @include flex_wrap($dis: flex,
                  $wrap: wrap,
                  $gap: calc(6px + (9 - 6) * ((100vw - 320px) / (1920 - 320))));

                body.rtl & {
                  padding-right: 0;
                }

                li {
                  width: 100%;

                  .size-box {
                    display: flex;
                    align-items: center;

                    h5 {
                      font-weight: 600;
                      margin-left: 8px;
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .dashboard-address {
      .address-box {
        border-radius: 8px;
        padding: calc(12px + (24 - 12) * ((100vw - 320px) / (1920 - 320)));
        background-color: $white;
        box-shadow: 0 0 9px rgba($black, 0.07);
        position: relative;
        height: 100%;
        overflow: hidden;

        >div {
          display: flex;
          position: relative;

          .label {
            position: absolute;
            top: 0;
            right: 0;
            background-color: var(--theme-color);
            padding: 2px 8px;
            border-radius: 4px;
            color: $white;
            font-size: 12px;
            letter-spacing: 0.8px;

            body.rtl & {
              right: unset;
              left: 0;
            }
          }

          .form-check {
            margin-top: 3px;

            .form-check-input {
              @include pseudowh($width: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320))),
                $height: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320))));
              margin-top: 2px;

              &:checked {
                background-color: var(--theme-color);
                border-color: var(--theme-color);
              }

              &:checked[type="radio"] {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
              }

              &:focus {
                box-shadow: none;
              }
            }
          }

          .address-table {
            table {
              margin-bottom: 0;

              tr {
                &:first-child {
                  td {
                    padding-top: 0;
                    font-weight: 600;
                    font-size: calc(17px + (18 - 17) * ((100vw - 320px) / (1920 - 320)));
                    color: $title-color;
                  }
                }

                td {
                  border: none;
                  font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                  padding: 4px 0;
                  color: $content-color;

                  &:first-child {
                    min-width: 90px;

                    body.rtl & {
                      padding-left: unset;
                    }
                  }

                  &:last-child {
                    p {
                      -webkit-box-orient: vertical;
                      display: -webkit-box;
                      overflow: hidden;
                      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                      margin-bottom: 0;
                      line-height: 1.5;
                    }
                  }

                  h4 {
                    @include flex_wrap($dis: flex, $wrap: wrap, $gap: 12px);
                    align-items: center;
                    font-weight: 500;
                    color: $title-color;

                    span {
                      background-color: var(--theme-color);
                      padding: 4px 8px;
                      border-radius: 4px;
                      color: $white;
                      font-size: 12px;
                      letter-spacing: 0.8px;
                    }
                  }
                }
              }
            }
          }
        }

        .button-group {
          @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 10px);
          align-items: center;
          justify-content: space-between;

          >* {
            width: 50%;
          }

          button {
            background-color: $light-gray;
            font-weight: 600;
            width: 100%;

            >div {
              display: flex;
              align-items: center;
              gap: 8px;
            }

            &:hover {
              background-color: var(--theme-color);
              color: $white;
            }

            .feather {
              @include pseudowh($width: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320))),
                $height: auto);
              margin-right: calc(6px + (8 - 6) * ((100vw - 320px) / (1920 - 320)));

              body.rtl & {
                margin-right: unset;
                margin-left: calc(6px + (8 - 6) * ((100vw - 320px) / (1920 - 320)));
              }
            }
          }
        }
      }

      .add-address {
        .address-link {
          @include flex_common;
          height: 100%;
          color: $title-color;
        }
      }
    }

    .dashboard-card {
      .payment-card-detail {
        position: relative;

        &:hover {
          .edit-card {
            opacity: 1;
            transition: all 0.3s ease-in-out;
          }
        }

        &.add-card {
          background-color: #f9f9f9;
          cursor: pointer;
          padding: 0;

          .card-details {
            @include flex_common;
            min-height: 130px;
            background: #d2d2d2;
            color: $title-color;
            text-align: center;

            h5 {
              margin-bottom: 0;
              text-transform: capitalize;
            }
          }
        }

        .card-details {
          height: 100%;
          color: $white;
          padding: 30px 20px;
          border-radius: 5px;
          background-color: #4b77a0;
          background-image:
            repeating-linear-gradient(45deg,
              rgba($white, 0) 1px,
              rgba($white, 0.03) 2px,
              rgba($white, 0.04) 3px,
              rgba($white, 0.05) 4px),
            -webkit-linear-gradient(-245deg, rgba($white, 0) 40%, rgba($white, 0.2) 70%, rgba($white, 0) 90%);

          &.card-visa {
            background-color: #777876;
          }

          &.dabit-card {
            background-color: #86b8cf;
          }

          .card-number {
            margin-bottom: 10px;

            h4 {
              color: $white;
              font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
            }
          }

          .valid-detail {
            display: flex;
            align-items: center;
            margin-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));

            .title {
              margin-bottom: 0;

              span {
                display: block;
                color: rgba($white, 0.7);
                text-transform: uppercase;
                font-size: 12px;
                line-height: 1.3;
              }
            }

            .date {
              h3 {
                margin-bottom: 0;
                color: $white;
                margin-left: 15px;
                font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
                font-weight: normal;

                body.rtl & {
                  margin-left: 0;
                  margin-right: 15px;
                }
              }
            }

            .primary {
              margin-left: auto;

              body.rtl & {
                margin-left: 0;
                margin-right: auto;
              }

              span {
                font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
                padding: 0;
                font-weight: 400;
                text-transform: capitalize;
              }
            }
          }

          .name-detail {
            @include flex_common($dis: flex, $align: center, $justify: space-between);
            margin-top: calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)));

            .name {
              h5 {
                text-transform: uppercase;
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                margin-bottom: 0;
                color: $white;
              }
            }

            .card-img {
              width: auto;
            }
          }
        }

        .edit-card {
          @include flex_common;
          @include pseudowh;
          position: absolute;
          text-align: center;
          top: 0;
          left: 0;
          background-color: rgba($title-color, $alpha: 0.8);
          border-radius: 5px;
          opacity: 0;
          transition: all 0.5s ease;

          @include mq-max(md) {
            display: none;
          }

          a {
            padding: 0 10px;
            text-transform: capitalize;
            color: $white;
          }
        }
      }

      .edit-card-mobile {
        display: none;

        @include mq-max(md) {
          @include flex_common;
          margin-top: 10px;

          a {
            padding: 0 10px;
            text-transform: capitalize;
            color: $title-color;
          }
        }
      }
    }

    .dashboard-profile {
      .profile-detail {
        .profile-name-detail {
          display: flex;
          align-items: center;

          @include mq-max(sm) {
            display: block;
          }

          h3 {
            font-weight: 400;
            font-size: 16px;
            margin-right: 10px;
            display: flex;
            align-items: center;

            body.rtl & {
              margin-right: unset;
              margin-left: 10px;
            }

            @include mq-max(2xs) {
              margin-right: 0;

              body.rtl & {
                margin-right: unset;
                margin-left: 0;
              }
            }

            span {
              background: var(--theme-color);
              color: $white;
              padding: 3px 8px;
              margin-left: 10px;
              border-radius: 5px;
              font-size: 13px;

              body.rtl & {
                margin-left: unset;
                margin-right: 10px;
              }
            }
          }

          .profile-rating {
            @include mq-max(sm) {
              margin-top: 3px;
            }
          }
        }

        a {
          margin-left: auto;
          padding: 8px 14px;
          position: relative;
          z-index: 0;
          border-radius: 5px;
          overflow: hidden;
          display: inline-block;

          body.rtl & {
            margin-left: unset;
            margin-right: auto;
          }

          @include mq-max(sm) {
            margin-top: 10px;
          }

          &::after {
            @include pos;
            @include pseudowh;
            top: 0;
            left: 0;
            background: var(--theme-color);
            opacity: 0.08;
            z-index: -1;
          }

          &:hover {
            color: var(--theme-color);
          }
        }

        .location-profile {
          margin-top: 20px;
          border-bottom: 1px solid $border-color;
          padding-bottom: 15px;

          ul {
            @include flex_wrap($dis: flex,
              $wrap: wrap,
              $gap: calc(10px + (26 - 10) * ((100vw - 320px) / (1920 - 320))));
            align-items: center;

            body.rtl & {
              padding-right: 0;
            }

            li {
              .location-box {
                @include flex_wrap($dis: flex, $wrap: wrap, $gap: 7px);
                align-items: center;
                color: $content-color;

                .feather {
                  @include pseudowh($width: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320))),
                    $height: auto);
                }

                h6 {
                  font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
                }
              }
            }
          }
        }

        .profile-description {
          p {
            margin: 20px 0 0;
            color: $content-color;
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
            line-height: 1.6;
          }
        }
      }

      .profile-about {
        margin-top: 20px;

        .form-control {
          border: none;
        }

        .table {
          margin-bottom: 36px;

          tbody {
            tr {
              td {
                vertical-align: middle;
                border: none;
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                white-space: nowrap;

                &:first-child {
                  color: $content-color;
                }

                a {
                  display: flex;
                  align-items: center;

                  &:hover {
                    color: var(--theme-color);
                  }

                  span {
                    margin-left: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)));
                    font-size: 14px;
                    padding: 5px 10px;
                    border-radius: 5px;
                    position: relative;
                    font-weight: 600;
                    overflow: hidden;
                    z-index: 0;

                    body.rtl & {
                      margin-left: unset;
                      margin-right: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320)));
                    }

                    &::before {
                      @include pos;
                      @include pseudowh;
                      top: 0;
                      left: 0;
                      background: var(--theme-color);
                      opacity: 0.08;
                      z-index: -1;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .dashboard-privacy {
      .privacy-box {
        +.privacy-box {
          margin-top: 15px;
        }

        h6 {
          font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
          margin-bottom: 6px;
          line-height: 1.5;
        }

        p {
          margin: 0;
          font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
          line-height: 1.6;
        }

        .switch-radio {
          .switch {
            position: absolute;
            clip-path: circle(0%);
            opacity: 0;

            &+label {
              @include pseudowh($width: 62px, $height: 25px);
              position: relative;
              border-radius: 50px;
              cursor: pointer;
              background-color: #0e997e26;
              border: 1px solid #ddd;

              &:before,
              &:after {
                display: inline-block;
                position: absolute;
              }

              &:before {
                @include pseudowh($width: 19px, $height: 19px);
                content: "";
                border-radius: 100%;
                left: 2px;
                top: 2px;
                background: var(--theme-color);
                transition: all 0.3s ease-in-out;
              }
            }

            &:checked {
              &+label {
                &:before {
                  left: 39px;
                }
              }
            }
          }
        }

        .switch-radio {
          .form-check-input {
            position: absolute;
            clip-path: circle(0%);
            opacity: 0;

            &+label {
              @include pseudowh($width: 62px, $height: 25px);
              position: relative;
              border-radius: 50px;
              cursor: pointer;

              &:before,
              &:after {
                display: inline-block;
                position: absolute;
              }

              &:before {
                @include pseudowh($width: 19px, $height: 19px);
                content: "";
                border-radius: 100%;
                left: 2px;
                top: 2px;
                background-color: var(--theme-color);
                transition: all 0.3s ease-in-out;
              }
            }

            &:checked {
              &+label {
                background-color: var(--theme-color);

                &:before {
                  left: 39px;
                  background: $white;
                }
              }
            }
          }
        }
      }
    }

    .card {
      margin-bottom: calc(19px + (30 - 19) * ((100vw - 320px) / (1920 - 320)));
    }

    .h-m30 {
      height: calc(100% - calc(19px + (30 - 19) * ((100vw - 320px) / (1920 - 320))));
    }
  }
}

.border-custom {
  padding-top: 30px;
  border-top: 1px solid #e9e9e9;
  display: block;
  margin-top: 30px;
}

.notification-count {
  width: 18px;
  height: 18px;
  font-size: 12px;
  background-color: var(--theme-color);
  border-radius: 100%;
  @include flex_common;
  color: #fff;
  margin-left: 7px;

  body.rtl & {
    margin-left: unset;
    margin-right: 7px;
  }
}

.themeform-auth {
  .form-label {
    font-size: 16px;
    font-weight: 600;
    position: relative;
    text-transform: capitalize;
    color: $content-color;
    margin-bottom: 0;
  }

  .form-control {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #444;
    padding: 13px 15px;
    border-color: #eee;
  }
}

.notification-list {
  li {
    display: block;
    background-color: $white;
    padding: calc(13px + (20 - 13) * ((100vw - 320px) / (1920 - 320))) calc(12px + (18 - 12) * ((100vw - 320px) / (1920 - 320)));
    padding-left: calc(33px + (46 - 33) * ((100vw - 320px) / (1920 - 320)));
    position: relative;

    body.rtl & {
      padding-left: calc(12px + (18 - 12) * ((100vw - 320px) / (1920 - 320)));
      padding-right: calc(33px + (46 - 33) * ((100vw - 320px) / (1920 - 320)));
    }

    &.unread {
      background-color: #e8f5f3;
      border-left: 3px solid var(--theme-color);
      position: relative;

      body.rtl & {
        border-left: unset;
        border-right: 3px solid var(--theme-color);
      }

      &::before {
        @include pos;
        @include pseudowh;
        top: 0;
        left: 0;
        background-color: var(--theme-color);
        opacity: 0.09;
      }
    }

    &::after {
      @include pos;
      left: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
      top: 31px;
      width: 5px;
      height: 5px;
      background-color: #8e8e8e;
      border-radius: 100%;

      body.rtl & {
        left: unset;
        right: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
      }
    }

    +li {
      border-top: 1px solid #eee;
    }
  }

  h4 {
    font-weight: 500;
    color: $title-color;
    line-height: 1.5;
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
  }

  h5 {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 5px;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: $content-color;
  }
}

.refund-table {
  tr {

    th,
    td {
      &:nth-child(4) {
        white-space: unset;
        text-align: revert;
      }
    }
  }
}

.wallet-table {
  margin-top: 30px;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;
  background-color: $white;

  @media (max-width: 1300px) {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  h4 {
    margin-bottom: 18px;
    font-weight: 600;
    font-size: 20px;
  }

  table {
    width: 100%;

    td,
    th {
      padding: 15px !important;
      text-align: center;
      white-space: nowrap;
    }

    td, th {
      &:nth-child(4) {
        @media (max-width: 1300px) {
          min-width: 500px;
        }
      }
    }

    th {
      font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
    }

    td {
      border-bottom: 1px solid #eee;
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    }

    tr {
      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }

    th {
      background-color: rgba(245, 245, 245, 0.8);
      font-weight: 500;
    }
  }
}

.wallet-bg {
  position: relative;
  z-index: 1;

  &:after {
    @include pos;
    background-image: url("../../images/withdraw-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    right: 0;
    top: 0;
    @include pseudowh;
    z-index: -1;
    opacity: 1;

    body.rtl & {
      transform: scaleX(-1);
      filter: FlipH;
    }
  }
}

.customer-detail {
  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      label {
        color: $title-color;
        text-transform: capitalize;
        margin-bottom: 4px;
        font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 500;
      }

      h4 {
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        line-height: 1.5;
        color: #777;
      }
    }
  }
}

.h-m30 {
  height: calc(100% - 30px);
}

.tracking-panel {
  ul {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    position: relative;
    gap: 15px;
    padding-right: 20px;
    overflow-y: hidden;
    overflow-x: auto;

    body.rtl & {
      padding-right: 0;
      padding-left: 20px;
    }

    li {
      position: relative;
      width: 100%;
      background-color: $white;
      border-radius: 0;
      padding: calc(13px + (17 - 13) * ((100vw - 320px) / (1920 - 320))) 0 calc(13px + (17 - 13) * ((100vw - 320px) / (1920 - 320))) calc(21px + (31 - 21) * ((100vw - 320px) / (1920 - 320)));

      body.rtl & {
        padding: calc(13px + (17 - 13) * ((100vw - 320px) / (1920 - 320))) calc(21px + (31 - 21) * ((100vw - 320px) / (1920 - 320))) calc(13px + (17 - 13) * ((100vw - 320px) / (1920 - 320))) 0;
      }

      &:after {
        opacity: 1;
        @include pos;
        top: 0;
        right: -16px;
        width: 0;
        height: 0;
        border-top: calc(28px + (40 - 28) * ((100vw - 320px) / (1920 - 320))) solid transparent;
        border-bottom: calc(28px + (40 - 28) * ((100vw - 320px) / (1920 - 320))) solid transparent;
        border-left: 17px solid #ffffff;
        transition: border-color 0.2s ease;

        body.rtl & {
          right: unset;
          left: -16px;
          border-right: 17px solid #ffffff;
          border-left: unset;
        }
      }

      &:before {
        @include pos;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-top: calc(28px + (40 - 28) * ((100vw - 320px) / (1920 - 320))) solid transparent;
        border-bottom: calc(28px + (40 - 28) * ((100vw - 320px) / (1920 - 320))) solid transparent;
        border-left: 17px solid #f9f9f6;
        transition: border-color 0.2s ease;

        body.rtl & {
          right: 0;
          left: unset;
          border-right: 17px solid #f9f9f6;
          border-left: unset;
        }
      }

      &.active {
        background-color: #e9f6f3;

        &:after {
          border-left: 17px solid #e9f6f3;

          body.rtl & {
            border-right: 17px solid #e9f6f3;
            border-left: unset;
          }
        }
      }

      &.cancelled-box {
        background-color: #fbe9eb;

        &:after {
          border-left: 17px solid #fbe9eb;

          body.rtl & {
            border-right: 17px solid #fbe9eb;
            border-left: unset;
          }
        }
      }

      &:first-child {
        border-radius: 6px 0 0 6px;

        body.rtl & {
          border-radius: 0 6px 6px 0;
        }

        &::before {
          display: none;
        }
      }
    }
  }

  .panel-content {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 0;

    .icon {
      width: calc(30px + (45 - 30) * ((100vw - 320px) / (1920 - 320)));
    }

    .status {
      margin-top: 0;
      font-size: 17px;
      display: flex;
      flex-direction: column;
      font-weight: 600;
      white-space: nowrap;
    }
  }
}

.tracking-total {
  li {
    @include flex_common ($dis: flex, $align: center, $justify: space-between);
    width: 100%;
    font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 6px;

    &:last-child {
      font-weight: 600;
      margin-bottom: 0;
      padding-top: 15px;
      border-top: 1px solid #e8e8e8;
      margin-top: 12px;
    }
  }
}
