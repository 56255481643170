/**=====================
     Item scss
==========================**/
.button-item {
  @include flex_common;
  @include center(vertical);
  position: fixed;
  right: 0;
  border-radius: 7px 0 0 7px;
  background-color: var(--theme-color);
  z-index: 8;

  .item-btn {
    @include pseudowh($width: calc(41px + (50 - 41) * ((100vw - 320px) / (1920 - 320))), $height: calc(41px + (50 - 41) * ((100vw - 320px) / (1920 - 320))));
    padding: 0;

    i {
      font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
    }
  }

  .badge {
    position: absolute;
    right: 22px;
    top: 10px;
    background-color: $white;
    border-radius: 100%;
    color: var(--theme-color);
    width: 18px;
    height: 18px;
    @include flex_common;
  }
}

.item-section {
  @include center(vertical);
  position: fixed;
  right: -140px;
  background-color: var(--theme-color);
  color: $white;
  border-radius: 7px 0 0 7px;
  padding: 15px;
  transition: all 0.5s ease-in-out;
  z-index: 9;

  &.active {
    right: 0;
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0;
    color: $white;
    border: none;
    background-color: transparent;
    line-height: 1;
  }

  h6 {
    @include flex_common;
    flex-wrap: nowrap;
    gap: 5px;
    margin-top: 10px;
    font-weight: bold;

    .icli,
    i {
      font-size: 22px;
      font-weight: normal
    }
  }

  .items-image {
    @include flex_common;
    margin: 10px 0;

    li {
      @include flex_common;
      @include pseudowh($width: 30px, $height: 30px);
      background-color: $white;
      border-radius: 50%;
      border: 1px solid var(--theme-color);
      color: var(--theme-color);
      font-weight: 600;
      font-size: 13px;

      +li {
        margin-left: -10px;
      }

      img {
        @include pseudowh($width: 15px, $height: 15px);
        object-fit: contain;
      }
    }
  }

  .item-button {
    background-color: $white;
    color: var(--theme-color);
  }
}
