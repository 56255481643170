/**=====================
     Loader scss
==========================**/
.spinning {
  padding-right: 40px !important;

  &::before {
    @include pos($pos: absolute, $content: "");
    width: 18px;
    height: 18px;
    border-radius: 50%;
    right: 10px;
    border: 2px solid rgba($white, 0.29);
    border-right: 3px solid $white;
    animation: rotate360 0.5s infinite linear;
  }
}

@keyframes rotate360 {
  100% {
    transform: rotate(360deg);
  }
}

.loader-wrapper,
.custom-loader-wrapper {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: $white;
  z-index: 999;
  @include flex_common;
  text-align: center;
  gap: 15px;
  top: 0;

  .loader {
    width: 48px;
    height: 48px;
    border: 5px solid #eaeaea;
    border-bottom-color: var(--theme-color);
    border-radius: 50%;
    display: inline-block;
    animation: rotation 1s linear infinite;
  }

  h3 {
    margin-top: 6px;
    color: #777;
    font-weight: 400;
  }
}

.custom-box-loader {
  position: relative;
  min-height: 350px;
  z-index: 0;

  .box-loader {

    .loader-wrapper,
    .custom-loader-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      padding: 0;
      gap: 0;
      z-index: 8;
      align-items: flex-start;

      >div {
        padding-top: 30%;
      }
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

div#loading-bar {
  color: var(--theme-deafult) !important;

  .bar {
    background: var(--theme-deafult) !important;
  }
}

#loading-bar-spinner {
  top: 15px !important;
  left: unset !important;
  right: 0 !important;
  margin-right: 20px;
  color: var(--theme-deafult) !important;

  .spinner-icon {
    width: 16px !important;
    height: 16px !important;
  }
}

.loader-bg {
  .ngx-bar {
    background-color: var(--theme-color) !important;
  }

  .ngx-spinner {
    color: var(--theme-color) !important;
    right: 10px;
    left: unset !important;
  }
}

// skeleton loader
.skeleton-body {
  background-image: none !important;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;

  .bg-effect {
    background-image: none;
  }

  .title {
    opacity: 0;
    visibility: hidden;
  }

  .skeleton-banner-xl {
    position: relative;

    .bg-size {
      background-color: #f8f8f8 !important;
      background-image: none !important;
    }

    [class*="col-"] {
      position: relative;
    }
  }

  .skeleton-banner-sm {
    position: relative;

    .bg-size {
      background-color: #f8f8f8 !important;
      background-image: none !important;
    }

    [class*="col-"] {
      position: relative;
    }

    .skeleton-text-wrap {
      .placeholder {
        min-height: 6.8%;
      }
    }
  }

  .skeleton-banner-vertical {
    position: relative;

    .bg-size {
      background-color: #f8f8f8 !important;
      background-image: none !important;
    }

    [class*="col-"] {
      position: relative;
    }

    .skeleton-text-wrap {
      justify-content: flex-start;

      .placeholder {
        min-height: 3.3%;
      }
    }
  }

  .skeleton-text-wrap {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    padding: 30px;

    .placeholder {
      min-height: 4.3%;
      background: linear-gradient(90deg, rgb(212, 212, 212, 0.41) 8%, #cdcdcd 18%, rgb(212, 212, 212, 0.41) 33%);
      background-size: 800px 104px;
      animation: skeleton-loader 2s infinite linear;
      border-radius: 10px;
    }
  }

  .home-section-2 {
    background-color: #f2f2f2;
    background-image: none !important;
  }

  .setting-box,
  .compare-fix,
  .recently-purchase {
    display: none;
  }
}

.skeleton-text-wrap {
  display: none;
}

.skeleton-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: max-content;
  padding: calc(8px + 6 * (100vw - 320px) / 1600);
  z-index: 0;
  background-color: #f9f9f6;
}

.skeleton *:empty {
  background: #f6f7f8;
  background-position: -500px 0;
  animation: skeletonShine 1s linear 0s infinite normal forwards;
  border-radius: 5px;
  background-image: linear-gradient(135deg, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 1000px 100%;
  width: 100%;

  [class="dark"] & {
    background-color: #1d1d22;
    background-image: linear-gradient(135deg, #1d1d22 0%, #2a2d2d 20%, #1d1d22 40%, #1d1d22 100%);
  }
}

.skeleton__section+.skeleton__section {
  margin-top: 16px;
}

.skeleton-section {
  width: 100%;
}

.skeleton__section--actions {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 16px;
}

.skeleton__section--card {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}

.skeleton__header {
  margin-bottom: 6px;
  height: 32px;
  width: 200px;
  max-width: 35%;
}

.skeleton__header--long {
  width: 300px;
  max-width: 85%;
}

.skeleton__p {
  height: 16px;
  width: 100%;
  margin-bottom: 8px;
}

.skeleton__p:last-child {
  width: 45%;
}

.skeleton__p--short {
  max-width: 120px;
}

.skeleton__img {
  height: 160px;
  width: 64px;
  border-radius: 8px;
}

.skeleton__tree .skeleton__p {
  width: 100%;
  max-width: none;
}

@keyframes skeletonShine {
  to {
    background-position: 500px 0;
  }
}

.skeleton-seller {
  .vendor-text {
    width: 66px;
    height: 66px;
    border-radius: 11px;
    background: #f6f7f8;
    background-position: -500px 0;
    animation: skeletonShine 1s linear 0s infinite normal forwards;
    background-image: linear-gradient(135deg, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 1000px 100%;
  }

  .contain-name {
    h3 {
      background: #f6f7f8;
      background-position: -500px 0;
      animation: skeletonShine 1s linear 0s infinite normal forwards;
      border-radius: 5px;
      background-image: linear-gradient(135deg, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
      background-repeat: no-repeat;
      background-size: 1000px 100%;
      width: 50%;
      height: 28px;
    }

    .product-label {
      background: #f6f7f8;
      background-position: -500px 0;
      animation: skeletonShine 1s linear 0s infinite normal forwards;
      border-radius: 5px;
      background-image: linear-gradient(135deg, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
      background-repeat: no-repeat;
      background-size: 1000px 100%;
      width: 20%;
      height: 28px;

      &:after {
        display: none;
      }
    }
  }

  .saller-contact {
    background: #f6f7f8;
    background-position: -500px 0;
    animation: skeletonShine 1s linear 0s infinite normal forwards;
    border-radius: 5px;
    background-image: linear-gradient(135deg, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 1000px 100%;
    width: 50% !important;
    height: 28px;

    &.mt-2 {
      width: 60% !important;
    }
  }
}

.blog-skeleton {
  .product-box {
    padding: 0;
    background-color: transparent;
  }

  .skeleton__img {
    height: 224px;
  }
}

.list_view {
  .skeleton {
    display: flex;
    align-items: center;
  }

  .skeleton__section--card {
    display: flex;
    flex-direction: row;
    margin-bottom: -1px;
    width: 38%;
    transition: all 0.3s ease-in-out;
    height: 300px;
  }

  .skeleton__img {
    height: 100%;
  }

  .content-div {
    width: 50%;
    margin-left: 30px;

    body.rtl & {
      margin-left: unset;
      margin-right: 30px;
    }
  }
}

.skeleton-vertical {
  .offer-image {
    width: 80px;
    height: 80px;
    background: #f6f7f8;
    background-position: -500px 0;
    animation: skeletonShine 1s linear 0s infinite normal forwards;
    background-image: linear-gradient(135deg, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 1000px 100%;
    border-radius: 7px;
  }

  &.offer-product {
    .offer-detail {
      .vertical-price {
        background: #f6f7f8;
        background-position: -500px 0;
        animation: skeletonShine 1s linear 0s infinite normal forwards;
        background-image: linear-gradient(135deg, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
        background-repeat: no-repeat;
        background-size: 1000px 100%;
        width: 120px;
        height: 21px;
        border-radius: 9px;
      }

      span {
        background: #f6f7f8;
        background-position: -500px 0;
        animation: skeletonShine 1s linear 0s infinite normal forwards;
        background-image: linear-gradient(135deg, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
        background-repeat: no-repeat;
        background-size: 1000px 100%;
        width: 30px;
        height: 20px;
        border-radius: 9px;
      }

      a {
        .name {
          background: #f6f7f8;
          background-position: -500px 0;
          animation: skeletonShine 1s linear 0s infinite normal forwards;
          background-image: linear-gradient(135deg, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
          background-repeat: no-repeat;
          background-size: 1000px 100%;
          width: 120px;
          height: 16px;
          border-radius: 9px;
        }
      }
    }
  }
}

.skeleton-offer {
  .coupon-box {
    padding: 40px 27px;

    .coupon-name {
      .card-name {
        h5 {
          background: #ffffff;
          background-position: -500px 0;
          animation: skeletonShine 2s linear 0s infinite normal forwards;
          border-radius: 5px;
          background-image: linear-gradient(135deg, #ffffff 0%, #e1e1e1 20%, #ffffff 40%, #ffffff 100%);
          background-repeat: no-repeat;
          background-size: 1000px 100%;
          opacity: 1;
          height: 24px;
          width: 140px;

          &::after {
            display: none;
          }
        }
      }
    }

    .coupon-content {
      p {
        background: #ffffff;
        background-position: -500px 0;
        animation: skeletonShine 2s linear 0s infinite normal forwards;
        border-radius: 5px;
        background-image: linear-gradient(135deg, #ffffff 0%, #e1e1e1 20%, #ffffff 40%, #ffffff 100%);
        background-repeat: no-repeat;
        background-size: 1000px 100%;
        opacity: 1;
        height: 22px;
        margin-bottom: 0;
        width: 70%;
      }

      .long {
        width: 90%;
        margin-top: 10px;
      }

      .long.long-s {
        width: 60%;
      }
    }
  }
}

.common-skeleton {
  ul {
    display: flex;
    flex-direction: column;
    padding: 0 !important;

    li {
      display: block !important;
    }

    .placeholder {
      background: #f6f7f8;
      background-position: -500px 0;
      animation: skeletonShine 1s linear 0s infinite normal forwards;
      border-radius: 5px;
      background-image: linear-gradient(135deg, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
      background-repeat: no-repeat;
      background-size: 1000px 100%;
      opacity: 1;
      height: 24px;

      +.placeholder {
        margin-top: 14px;
      }
    }
  }

  .img-box {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    .img-content {
      width: 80%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-left: 40px;

      body.rtl & {
        margin-left: unset;
        margin-right: 40px;
      }
    }

    .img {
      width: 230px;
      height: 230px;
      border-radius: 15px;
      background: #f6f7f8;
      background-position: -500px 0;
      animation: skeletonShine 1s linear 0s infinite normal forwards;
      background-image: linear-gradient(135deg, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
      background-repeat: no-repeat;
      background-size: 1000px 100%;
      opacity: 1;
    }

    .placeholder {
      background: #f6f7f8;
      background-position: -500px 0;
      animation: skeletonShine 1s linear 0s infinite normal forwards;
      border-radius: 5px;
      background-image: linear-gradient(135deg, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
      background-repeat: no-repeat;
      background-size: 1000px 100%;
      opacity: 1;
      height: 20px;
    }
  }
}

@keyframes skeleton-loader {
  0% {
    transform: translateZ(0);
    background-position: -468px 0;
  }

  100% {
    transform: translateZ(0);
    background-position: 468px 0;
  }
}

.product-section-box {
  .question-answer {
    &.skeleton-qa {
      li {
        h6 {
          background: linear-gradient(90deg, rgb(212, 212, 212, 0.41) 8%, #cdcdcd 18%, rgb(212, 212, 212, 0.41) 33%);
          background-size: 800px 104px;
          animation: skeleton-loader 2s infinite linear;
          height: 20px;
          width: 15%;
        }

        .answer-box {
          >div {
            width: 100%;
          }

          p {
            background: linear-gradient(90deg, rgb(212, 212, 212, 0.41) 8%, #cdcdcd 18%, rgb(212, 212, 212, 0.41) 33%);
            background-size: 800px 104px;
            animation: skeleton-loader 2s infinite linear;
            height: 20px;
            width: 40%;
            margin-bottom: 6px;

            &:nth-child(2) {
              width: 60%;
            }

            &:nth-child(3) {
              width: 50%;
            }
          }
        }

        .answer-box,
        .question-box {
          h5 {
            border: none;
            background-color: #f8f8f8;
          }
        }
      }
    }
  }
}
