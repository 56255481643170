/**=====================
    Tabs CSS Start
==========================**/
.vertical-tabs {
  .nav-pills {
    border: none;
    position: sticky;
    top: 100px;

    @media (max-width: 991px) {
      display: flex;
      flex-wrap: unset;
      position: relative;
      top: 0;
      flex-direction: unset !important;
      gap: 15px;
      margin-bottom: 20px;
      overflow: auto;
    }

    .nav-item {
      display: block;
      width: 100%;
      margin: 0;
      position: relative;
      background-color: #f8f8f8;
      margin-bottom: 6px;
      border-radius: 6px;

      @media (max-width: 991px) {
        margin-bottom: 0;
        display: inline-block;
        width: max-content;
        margin: 0;
      }

      &.is-invalid {
        border-color: transparent;
        border: none !important;
        padding-right: 0 !important;
        background-image: unset;

        .nav-link {
          &:before {
            @include pos($pos: absolute, $content: "");
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='15' height='15' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
            background-position: right 0.75rem center, center right 2.25rem !important;
            background-size: 18px !important;
            background-repeat: no-repeat;
            border-color: #dc354500 !important;
            z-index: 1;
            width: 30px;
            height: 30px;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }

          &.active {
            &:before {
              filter: invert(1) brightness(100);
            }
          }
        }
      }

      .nav-link {
        width: 100%;
        text-align: left;
        border-radius: 0;
        padding: 12px 25px;
        display: flex;
        align-items: center;
        gap: 13px;
        color: #212529;
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 500;
        text-transform: capitalize;

        @media (max-width: 991px) {
          padding: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320))) calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
          gap: 8px;
          white-space: nowrap;
        }

        &.active {
          background: linear-gradient(-45deg, var(--theme-color), #009289);
          border-radius: 6px;
          font-weight: 500;
          color: $white;
        }
      }

      +.nav-item {
        margin-top: 8px;

        @media (max-width: 991px) {
          margin-top: 0;
        }
      }
    }
  }

  .tab-content {
    border-left: 1px dashed #ddd;
    padding-left: 24px;
    height: 100%;

    body.rtl & {
      padding-left: 0;
      padding-right: 24px;
      border-left: none;
      border-right: 1px dashed #ddd;

      @media (max-width: 991px) {
        border-right: none;
        padding-right: 0;
      }
    }

    @media (max-width: 991px) {
      border-top: 1px dashed #ddd;
      padding-top: 20px;
      border-left: none;
      padding-left: 0;
    }
  }
}

.variant-row {
  .row {
    .col-sm-2,
    .col-sm-10 {
      width: 100% !important;
    }
  }
}

.delete-variant {
  @include flex_common;

  @media (max-width: 576px) {
    .invalid-feedback {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }
}

.variant-box {
  &.border-top {
    padding-top: 20px;
    border-top: 1px solid #eee;
  }
}

.inside-horizontal-tabs {
  @media (max-width: 991px) {
    margin-top: -32px;
    background-color: $white;
  }

  .nav-tabs {
    display: flex;
    align-items: center;
    flex-wrap: unset;
    border-bottom: 1px solid #efefef;
    overflow-x: auto;
    overflow-y: hidden;

    * {
      scrollbar-width: auto;
      scrollbar-color: $white $white;
    }

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    &::-webkit-scrollbar-track {
      background: $white;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $white;
      border-radius: 10px;
      border: 3px solid $white;
    }

    .nav-item {
      display: inline-block;
      width: auto;
      border-radius: 6px;
      background-color: transparent;
      margin-bottom: 0;

      .nav-link {
        background-color: transparent;
        border-radius: 0;
        padding: 12px 20px;
        color: #212529;
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        border: none;
        text-transform: capitalize;
        display: flex;
        gap: 10px;
        white-space: nowrap;

        &.active {
          background: transparent;
          color: var(--theme-color);
          border-radius: 0;
          font-weight: 600;
          border: none;
          border-bottom: 2px solid var(--theme-color);
        }
      }

      +.nav-item {
        margin-top: 0;
      }
    }
  }

  .tab-content {
    padding-left: 0;
    border-left: none;
    padding-top: 10px;

    body.rtl & {
      border-right: none;
    }

    @media (max-width: 991px) {
      border-top: none;
      padding-top: 0;
    }
  }
}


.tab-style-color {
  @include flex_wrap ($dis: flex, $wrap: nowrap, $gap: calc(8px + (20 - 8) * ((100vw - 320px) / (1920 - 320))));
  border: none;
  overflow-x: auto;
  overflow-y: hidden;

  @media (max-width: 1265px) {
    margin-top: 13px;
  }

  &-2 {
    @media (max-width: 1265px) {
      margin-top: 0;
    }

    @media (max-width: 850px) {
      margin-top: 13px;
      padding-bottom: 11px;
    }
  }

  .nav-item {
    .nav-link {
      border: none;
      color: $title-color;
      font-weight: 500;
      transition: all 0.3s ease-in-out;
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
      white-space: nowrap;
      background-color: $white;
      line-height: 1;
      border: 1px solid var(--theme-color);
      padding: calc(9px + (14 - 9) * ((100vw - 320px) / (1920 - 320))) calc(14px + (30 - 14) * ((100vw - 320px) / (1920 - 320)));
      margin: 0;

      &.active,
      &:hover {
        background-color: var(--theme-color);
        color: $white;
      }

      img {
        margin-right: 5px;

        body.rtl & {
          margin-right: unset;
          margin-left: 5px;
        }
      }
    }
  }
}
