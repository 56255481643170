/**=====================
    Counter scss
==========================**/
.counter {
  display: flex;
  align-items: center;

  .qty-left-minus,
  .qty-right-plus {
    @include flex_common;
    @include pseudowh($width: calc(30px + (35 - 30) * ((100vw - 320px) / (1920 - 320))), $height: calc(30px + (35 - 30) * ((100vw - 320px) / (1920 - 320))));
    border: 1px solid $border-color;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 20px;
    line-height: 24px;
    user-select: none;
    border-radius: 0;

    i {
      font-size: 12px;
      margin-top: 3px;
    }

    &:hover {
      color: darken(#74b816, 40%);
    }
  }

  .qty-left-minus {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: 0;

    body.rtl & {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-right: 1px solid $border-color;
      border-left: 0;
    }
  }

  .qty-right-plus {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: 0;

    body.rtl & {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
      border-left: 1px solid $border-color;
    }
  }

  input {
    @include pseudowh($width: 45px, $height: calc(30px + (35 - 30) * ((100vw - 320px) / (1920 - 320))));
    border: 1px solid $border-color;
    appearance: none;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    background-color: rgba($black, .06);
    border-radius: 0;

    body.rtl & {
      text-align: center;
    }
  }
}
