/**=====================
     Slider scss
==========================**/
.slider-dots {
  .owl-theme {
    .owl-dots {
      .owl-dot {
        &.active {
          span {
            width: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
            background-color: var(--theme-color);
            transition: all 0.5s ease;
          }
        }

        &:hover {
          span {
            background: rgba(35, 35, 35, 0.2);
          }
        }

        span {
          margin: 0 5px;
          width: calc(6px + (8 - 6) * ((100vw - 320px) / (1920 - 320)));
          height: calc(6px + (8 - 6) * ((100vw - 320px) / (1920 - 320)));
          background: rgba(35, 35, 35, 0.2);
          transition: all 0.5s ease;
          border-radius: 100px;
        }
      }
    }
  }
}

.product-wrapper {
  .timer {
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    text-align: center;
  }
}

.arrow-slider {

  .owl-prev,
  .owl-next {
    @include pseudowh($width: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320))), $height: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320))));
    background-color: $white;
    box-shadow: 0px 1px 4px rgba($title-color, .14);
    position: absolute;
    top: calc(-28px + (-43 - -28) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 5px;
    transition: all 0.3s ease-in-out;

    &::before {
      @include font;
      color: var(--theme-color);
      font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
    }

    &:hover {
      background-color: var(--theme-color);

      &::before {
        color: $white;
      }
    }
  }

  .owl-prev {
    left: unset;
    right: 60px;

    body.rtl & {
      right: unset;
      left: 0;
    }

    &::before {
      content: "\f104";
    }

    @include mq-max(sm) {
      right: 45px;
    }
  }

  .owl-next {
    right: 5px;

    body.rtl & {
      right: unset;
      left: 50px;
    }

    &::before {
      content: "\f105";
    }

    @include mq-max(sm) {
      body.rtl & {
        left: 40px;
      }
    }
  }
}

.arrow-slider-2 {

  .owl-prev,
  .owl-next {
    top: -42px;

    @include mq-max(3xl) {
      top: -38px;
    }

    @include mq-max(xl) {
      top: -37px;
    }

    @include mq-max(md) {
      top: -33px;
    }

    @include mq-max(xs) {
      top: -31px;
    }

    @include mq-max(2xs) {
      top: -29px;
    }
  }

  .owl-prev {
    left: unset;
    right: 50px;

    body.rtl & {
      right: unset;
      left: 0;
    }

    &::before {
      @include font;
      content: "\f053";
      color: $title-color;
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      &::before {
        color: var(--theme-color);
      }
    }
  }

  .owl-next {
    right: 5px;

    body.rtl & {
      right: unset;
      left: 30px;
    }

    &::before {
      @include font;
      content: "\f054";
      color: $title-color;
    }

    &:hover {
      &::before {
        color: var(--theme-color);
      }
    }
  }
}

.img-slider {
  img {
    display: initial;
  }
}

.product-arrow {
  .owl-theme {
    .owl-nav {

      .owl-prev,
      .owl-next {
        @include flex_common;
        @include pseudowh($width: calc(28px + (40 - 28) * ((100vw - 320px) / (1920 - 320))) !important, $height: calc(28px + (40 - 28) * ((100vw - 320px) / (1920 - 320))) !important);
        background-color: $border-color;
        border-radius: 5px;
        z-index: 1;
        transition: all 0.3s ease;
        position: absolute;
        display: flex !important;
        align-items: center;
        justify-content: center;
        line-height: 0px;
        font-size: 0px;
        cursor: pointer;
        top: 50%;
        transform: translate(0, -50%);
        font-size: 0;

        &::before {
          @include remixicon;
          font-size: 22px;
        }
      }

      .owl-prev {
        left: 0;

        &::before {
          color: $black;
          content: "\ea64";
        }

        &:hover,
        &:focus {
          background-color: var(--theme-color);

          &::before {
            color: $white;
          }
        }
      }

      .owl-next {
        right: 0;

        body.rtl & {
          right: unset;
          left: 91%;
        }

        &:before {
          color: $black;
          content: "\ea6e";
        }

        &:hover,
        &:focus {
          background-color: var(--theme-color);

          &::before {
            color: $white;
          }
        }
      }
    }
  }
}

.product-box-arrow {
  .owl-prev,
  .owl-next {
    @include flex_common;
    @include pseudowh($width: calc(28px + (40 - 28) * ((100vw - 320px) / (1920 - 320))), $height: calc(28px + (40 - 28) * ((100vw - 320px) / (1920 - 320))));
    border-radius: 50%;
    z-index: 1;
    transition: all .3s ease;
    background-color: $white;
    box-shadow: 0px 3px 4px rgba($black, .14);
    color: $content-color;

    &::before {
      @include font;
      color: $black;
      font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    }

    &:hover,
    &:focus {
      background-color: var(--theme-color);

      &::before {
        color: $white;
      }
    }
  }

  .owl-prev {
    left: -28px;

    @include mq-max(sm) {
      left: -8px;
    }

    &::before {
      content: "\f104";
    }
  }

  .owl-next {
    right: -28px;

    @include mq-max(sm) {
      right: -8px;
    }

    &:before {
      content: "\f105";
    }
  }
}
