/**=====================
  Tooltip scss
==========================**/
.tooltip {
  transition: opacity 0.15s linear;

  .tooltip-arrow {
    &::before {
      border-top-color: var(--theme-color);
    }
  }

  .tooltip-inner {
    font-weight: 500;
    background-color: var(--theme-color);
    color: $white;
  }
}
